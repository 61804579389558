<template>
  <div class="w-100">
    <md-card
      v-if="invalid"
      class="w-100 text-center"
    >
      <md-card-header class="py-5">
        <md-icon class="md-size-3x text-warning">
          warning_amber
        </md-icon>
        <h3 class="title">
          Letter Not Found
        </h3>
        <h5 class="category">
          Contact your agent plase.
        </h5>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    invalid: true,
  }),
  mounted() {
    this.axios.get(`${process.env.VUE_APP_SERVER}letters/${this.$route.params.token}`)
      .then(({ data }) => {
        window.location = data.data;
        this.invalid = false;
      })
      .catch((err) => {
        console.error(err);
        this.invalid = true;
      });
  },
};
</script>

<style scoped lang="scss">
::v-deep .md-card-header {
  h3.title {
    color: #3c4858 !important;
    margin-top: 10px;
  }
  h5.category {
    margin-bottom: 0;
  }
  small {
    display: inline-block;
    margin-bottom: 20px;
  }
}
.page-header .container {
  padding-bottom: 80px !important;
}
</style>
